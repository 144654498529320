import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Image, Switch } from 'antd';
import { withElipsis } from '../../utils';

export const MarkdownColumn = (body) => (<div>
  <ReactMarkdown>
      {withElipsis(body)}
  </ReactMarkdown>
</div>);

export const ImageColumn = (image) => (<div>
<Image
  width={150}
  height={150}
  src={image}
/>
</div>);

export const IframeColumn = (url) => (<div>
<iframe
  src={url}
  width="200"
  height="150"
  allowfullscreen
/>
</div>);

export const SwitchColumn = (active) => (<div>
  <Switch checked={active} disabled/>
</div>);