import React from "react";
import { Typography } from 'antd';

const { Paragraph, Text } = Typography;

const ConfirmationDeleteMessage = ({ current }) => (
<Paragraph>¿Seguro que desea eliminar la entrada <Text strong italic>"{current}"</Text>?</Paragraph>
);

export default ConfirmationDeleteMessage;
