import React, { useState } from "react";
import { Col, Form, Input } from 'antd';
import { defaultIframe } from '../../constants';

const IframePreviewForm = ({ optional = false, preview, label = 'Link', name = 'link' }) => {
  const [previewIframe, updateIframe] = useState(preview ?? defaultIframe);

  return (<div>
    <Form.Item
      label={label}
      name={name}
      rules={[{ required: !optional, message: 'Por favor ingresa el link del iframe' }]}
      onChange={({ target }) => updateIframe(target.value)}
    >
      <Input />
    </Form.Item>
    <Col span={8} offset={8}>
      <iframe
        src={previewIframe}
        width="200"
        height="150"
        allowfullscreen
      />
    </Col>
  </div>);
}

export default IframePreviewForm;
