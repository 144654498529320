import React from "react";
import screenColumns from "./screenColumns";
import ScreenForm from "./ScreenForm";
import ScreenBase from "../../common/ScreenBase";
import { DATABASE_REFS } from "../../constants";

const InterestSubjects = () => (<ScreenBase
  Form={ScreenForm}
  screenColumns={screenColumns}
  databaseRef={DATABASE_REFS.INTEREST_SUBJECTS}
  title='Temas de intéres'
/>);

export default InterestSubjects;
