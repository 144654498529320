import { SwitchColumn } from "../../common/ColumnRenders";

const screenColumns = [
  {
    title: 'Aprobado',
    dataIndex: 'approved',
    key: 'approved',
    render: SwitchColumn,
  },
  {
    title: 'Activo',
    dataIndex: 'is_active',
    key: 'approved',
    render: SwitchColumn,
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Apellidos',
    dataIndex: 'lastname',
    key: 'lastname',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
  },
  {
    title: 'Comités solicitados',
    dataIndex: 'selected_committees',
    key: 'selectedCommittees',
    render: (data) => {
      return (<ul>
        {data?.split(',').map((comm) => (<li>
          {comm}
        </li>))}
      </ul>);
    }
  },
  {
    title: 'Interéses solicitados',
    dataIndex: 'selected_interests',
    key: 'selectedInterests',
    render: (data) => {
      return (<ul>
        {data?.split(',').map((comm) => (<li>
          {comm}
        </li>))}
      </ul>);
    }
  },
];

export default screenColumns;
