import React, { useEffect, useState } from "react";
import { Col, Button, Form, Input, Switch, Typography } from 'antd';
import '../../index.css';
import api2 from "../../api2";
const { Text } = Typography;

const ListMatcherFromDB = ({ title, table, baseItems, matcherItems, onChange, onSetPreselected }) => {
  const [keysMerged, updateTagsMerged] = useState([]);
  useEffect(() => {
    api2.getData(table, (data) => {
      const auxKeys = baseItems?.map((key) => {
        const match = data?.find((d) => d.key === key)
        return {
          name: match?.name,
          key,
        };
      });
      updateTagsMerged(auxKeys);
      if (matcherItems) {
        const auxSelectedComm = matcherItems?.filter((mI) => data?.find((d) => d.key === mI));
        onSetPreselected(auxSelectedComm); 
      }
    });
  }, []);

  return (<Col span={12} offset={4}>
    <Text strong>{title}</Text>
    {keysMerged && (<ul>
      {keysMerged.map((keyM) => {
        return (<li>
          <div className="ListMatcherWrapper">
            <Switch
              defaultChecked={matcherItems?.find((mI) => keyM.key === mI)}
              onChange={(toAdd) => onChange(keyM.key, toAdd)}
            />
            <span>
              {keyM.name}
            </span>
          </div>
        </li>);
      })}
    </ul>)}
  </Col>);
};

const ScreenForm = ({ onFinish, initialValues }) => {
  const [newApprovedCommittees, updateNewApprovedCommittees] = useState([]);
  const [newApprovedInterests, updateNewApprovedInterests] = useState([]);

  const handleOnFinish = (data) => onFinish({
    ...data,
    approvedCommittees: newApprovedCommittees?.join(','),
    approvedInterests: newApprovedInterests?.join(','),
  });

  const mappedInitialValues = {
    ...initialValues,
    isActive: initialValues['is_active'],
    approvedCommittees: initialValues['approved_committees'],
    selectedCommittees: initialValues['selected_committees'],
    approvedInterests: initialValues['approved_interests'],
    selectedInterests: initialValues['selected_interests'],
  }

  return (<Form
    name="UserData"
    labelCol={{ offset: 1, span: 4 }}
    wrapperCol={{ span: 16 }}
    onFinish={handleOnFinish}
    initialValues={mappedInitialValues}
  >
    <Form.Item
      label="Nombre"
      name="name"
      rules={[{ required: true, message: 'Por favor ingresa el titulo del tema' }]}
    >
      <Input />
    </Form.Item>
  
    <ListMatcherFromDB
      title="Comités"
      baseItems={mappedInitialValues.selectedCommittees?.split(',')}
      matcherItems={mappedInitialValues.approvedCommittees?.split(',')}
      table="committees"
      onSetPreselected={updateNewApprovedCommittees}
      onChange={(tag, toAdd) => {
        console.log('tag, toAdd', tag, toAdd);
        if (toAdd) {
          updateNewApprovedCommittees([...newApprovedCommittees, tag]);
        } else {
          const auxComm = newApprovedCommittees?.filter((cT) => cT !== tag);
          updateNewApprovedCommittees(auxComm);
        }
      }}
    />
    <ListMatcherFromDB
      title="Interéses"
      baseItems={mappedInitialValues.selectedInterests?.split(',')}
      matcherItems={mappedInitialValues.approvedInterests?.split(',')}
      table="interest_subjects"
      onSetPreselected={updateNewApprovedInterests}
      onChange={(tag, toAdd) => {
        if (toAdd) {
          updateNewApprovedInterests([...newApprovedInterests, tag]);
        } else {
          const auxComm = newApprovedInterests?.filter((cT) => cT !== tag);
          updateNewApprovedInterests(auxComm);
        }
      }}
    />
  
    <Form.Item
      label="Empresa"
      name="company"
      rules={[{ required: true, message: 'Por favor ingresa la tag del tema' }]}
    >
      <Input />
    </Form.Item>
  
    <Form.Item
      label="Aprobado"
      name="approved"
      valuePropName="approved"
    >
      <Switch defaultChecked={mappedInitialValues.approved} />
    </Form.Item>

    <Form.Item
      label="Activo"
      name="isActive"
      valuePropName="isActive"
    >
      <Switch defaultChecked={mappedInitialValues.isActive} />
    </Form.Item>
    
    <Form.Item
      label="Correo"
      name="email"
      rules={[{ required: true, message: 'Por favor ingresa la tag del tema' }]}
    >
      <Input />
    </Form.Item>
  
    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
