import React from "react";
import screenColumns from "./screenColumns";
import ScreenForm from "./ScreenForm";
import ScreenBase from "../../common/ScreenBase";
import { DATABASE_REFS } from "../../constants";

const Calendar = () => (<ScreenBase
  Form={ScreenForm}
  screenColumns={screenColumns}
  databaseRef={DATABASE_REFS.CALENDAR}
  title='Calendario'
/>);

export default Calendar;
