import React from "react";
import { Button, Form, Input, } from 'antd';
import '../../index.css';

const ScreenForm = ({ onFinish, initialValues }) => (<Form
  name="InterestSubject"
  labelCol={{ offset: 1, span: 4 }}
  wrapperCol={{ span: 16 }}
  onFinish={onFinish}
  initialValues={initialValues}
>
  <Form.Item
    label="Título"
    name="title"
    rules={[{ required: true, message: 'Por favor ingresa el titulo del tema' }]}
  >
    <Input />
  </Form.Item>

  <Form.Item
    label="Tag"
    name="tag"
    rules={[{ required: true, message: 'Por favor ingresa la tag del tema' }]}
  >
    <Input />
  </Form.Item>

  <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
    <Button type="primary" htmlType="submit">
      Submit
    </Button>
  </Form.Item>
</Form>);

export default ScreenForm;
