import React, { useState } from "react";
import { Col, Image, Form, Input } from 'antd';
import { defaultImage } from '../../constants';

const ImagePreviewForm = ({ preview, name = "url_image" }) => {
  const [previewImage, updatePreview] = useState(preview ?? defaultImage);

  return (<div>
    <Form.Item
      label="Imagen"
      name={name}
      rules={[{ required: true, message: 'Por favor ingresa la imagen' }]}
      onChange={({ target }) => updatePreview(target.value)}
    >
      <Input />
    </Form.Item>
    <Col span={8} offset={8}>
      <Image
        width={200}
        src={previewImage}
      />
    </Col>
  </div>);
}

export default ImagePreviewForm;
