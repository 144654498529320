import React, { useState } from "react";
import { Button, Form, Input, Image } from 'antd';
import moment from 'moment';
import '../../index.css';
import { defaultImage } from "../../constants";
import ImagePreviewForm from "../../common/ImagePreviewForm";

const ScreenForm = ({ onFinish, initialValues = {} }) => {

  return (<Form
    name="Partners"
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 18 }}
    onFinish={onFinish}
    initialValues={initialValues}
  >
    
    <Form.Item
      label="Nombre del patrocinador"
      name="name"
      rules={[{ required: true, message: 'Por favor ingresa el nombre del patrocinador' }]}
    >
      <Input />
    </Form.Item>

    <ImagePreviewForm
      preview={initialValues.image}
    />

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Aceptar
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
