import { MarkdownColumn } from "../../common/ColumnRenders";

const screenColumns = [
  {
    title: 'Titulo',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Descripción',
    dataIndex: 'body',
    key: 'body',
    render: MarkdownColumn,
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
];

export default screenColumns;
