import React, { useRef } from "react";
import TextareaMarkdown from "textarea-markdown-editor";
import MarkdownButtons from "./MarkdownButtons";

const MarkdownEditor = ({ onChange, text }) => {
  const ref = useRef(null);

  const handleChange = ({ target }) => {
    onChange(target.value);
  }

  return (<div>
    <MarkdownButtons markdownRef={ref} />
    <TextareaMarkdown rows={8} className="ant-input" ref={ref} value={text} onChange={handleChange} />
  </div>);
};

export default MarkdownEditor;
