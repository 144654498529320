import React from "react";
import screenColumns from "./screenColumns";
import ScreenForm from "./ScreenForm";
import ScreenBase from "../../common/ScreenBase";
import { DATABASE_REFS } from "../../constants";

const Magazines = () => (<ScreenBase
  Form={ScreenForm}
  screenColumns={screenColumns}
  databaseRef={DATABASE_REFS.MAGAZINES}
  title='Revistas'
/>);

export default Magazines;
