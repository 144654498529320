import React from "react";
import { Button, Row, Col } from 'antd';
import './styles.css';

const TagsButtons = ({ tags, onSelect }) => (<Row>
  <Col>
    {tags.map((tag) => (<Button
      type="primary"
      htmlType="button"
      onClick={() => onSelect(tag)}
      className="markdownButton"
    >
      {`${tag} (X)`}
    </Button>))}
  </Col>
</Row>);

export default TagsButtons;