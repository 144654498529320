import React from "react";
import { DesktopOutlined, PieChartOutlined } from '@ant-design/icons';
import InterestSubjects from "../Screens/InterestSubjects";
import Users from "../Screens/Users";
import Committees from "../Screens/Committees";
import News from "../Screens/News";
import Reports from '../Screens/Reports';
import Events from '../Screens/Events';
import Magazines from '../Screens/Magazines';
import Circulares from '../Screens/Circulares';
import Webinars from '../Screens/Webinars';
import Indicators from '../Screens/Indicators';
import AntpLive from '../Screens/ANTPLive';
import Partners from '../Screens/Partners';
import Calendar from '../Screens/Calendar';
import Notifications from '../Screens/Notifications';

const routes = [{
  key: 'interest-subjects',
  icon: () => <PieChartOutlined />,
  link: '/interest-subjects',
  label: 'Temas de interés',
  screen: () => <InterestSubjects />,
},
{
  key: 'committees',
  icon: () => <DesktopOutlined />,
  link: '/committees',
  label: 'Comités',
  screen: () => <Committees />,
},
{
  key: 'news',
  icon: () => <DesktopOutlined />,
  link: '/news',
  label: 'Noticias',
  screen: () => <News />,
},
{
  key: 'reports',
  icon: () => <DesktopOutlined />,
  link: '/reports',
  label: 'Informes',
  screen: () => <Reports />,
},
{
  key: 'events',
  icon: () => <DesktopOutlined />,
  link: '/events',
  label: 'Eventos',
  screen: () => <Events />,
},
{
  key: 'magazines',
  icon: () => <DesktopOutlined />,
  link: '/magazines',
  label: 'Revistas',
  screen: () => <Magazines />,
},
{
  key: 'circulares',
  icon: () => <DesktopOutlined />,
  link: '/circulares',
  label: 'Circulares',
  screen: () => <Circulares />,
},
{
  key: 'webinars',
  icon: () => <DesktopOutlined />,
  link: '/webinars',
  label: 'Webinars',
  screen: () => <Webinars />,
},
{
  key: 'indicators',
  icon: () => <DesktopOutlined />,
  link: '/indicators',
  label: 'Indicadores',
  screen: () => <Indicators />,
},
{
  key: 'antp-live',
  icon: () => <DesktopOutlined />,
  link: '/antp-live',
  label: 'ANTP Live',
  screen: () => <AntpLive />,
},
{
  key: 'partners',
  icon: () => <DesktopOutlined />,
  link: '/partners',
  label: 'Nuestros socios',
  screen: () => <Partners />,
},
{
  key: 'calendar',
  icon: () => <DesktopOutlined />,
  link: '/calendar',
  label: 'Calendario',
  screen: () => <Calendar />,
},
{
  key: 'users',
  icon: () => <DesktopOutlined />,
  link: '/users',
  label: 'Usuarios',
  screen: () => <Users />,
},
{
  key: 'notifications',
  icon: () => <DesktopOutlined />,
  link: '/notifications',
  label: 'Notificaciones',
  screen: () => <Notifications />,
}].sort((a, b) => {
  const aLower = a.label.toLowerCase();
  const bLower = b.label.toLowerCase();
  if (aLower < bLower) {
    return -1;
  }
  if ( aLower > bLower) {
    return 1;
  }
  return 0;
});

export default routes;
