import React, { useState } from "react";
import { Button, Form, Input, DatePicker } from 'antd';
import moment from 'moment';
import '../../index.css';
import { formatDate } from "../../constants";
import MarkdownEditor from "../../common/MarkdownEditor";
import ImagePreviewForm from "../../common/ImagePreviewForm";
import IframePreviewForm from "../../common/IframePreviewForm";

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const [body, setBody] = useState(initialValues.body);
  const defaultDate = initialValues.date ? moment(initialValues.date, formatDate) : moment();
  const handleOnFinish = (data) => onFinish({ ...data, body, date: data.date.format(formatDate)});

  const trueInitialValues = { ...initialValues, date: defaultDate };

  return (<Form
    name="ANTPlive"
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 18 }}
    onFinish={handleOnFinish}
    initialValues={trueInitialValues}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Por favor ingresa el titulo del Live' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Descripción"
      name="body"
      rules={[{ required: true, message: 'Por favor ingresa la descripción del Live' }]}
    >
      <MarkdownEditor body={body} onChange={setBody} />
    </Form.Item>
    
    <Form.Item
      label="Número de emisión"
      name="emitionNumber"
      rules={[{ required: true, message: 'Por favor ingresa el número de emisión del Live' }]}
    >
      <Input />
    </Form.Item>

    <ImagePreviewForm
      preview={initialValues.image}
    />

    <IframePreviewForm
      preview={initialValues.link}
    />
    
    <Form.Item
      label="Día de transmisión"
      name="date"
      rules={[{ required: true, message: 'Por favor ingresa el día de transmisión del Live' }]}
    >
      <DatePicker format={formatDate} />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
