import React, { useState, useEffect } from "react";
import { Button, Form, Input, DatePicker } from 'antd';
import '../../index.css';
import moment from 'moment';
import { formatDate } from "../../constants";
import MarkdownEditor from "../../common/MarkdownEditor";
import ImagePreviewForm from "../../common/ImagePreviewForm";

const api = {};

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const [body, setBody] = useState(initialValues.body);
  const [tokens, updateTokens] = useState([]);
  const defaultDate = initialValues.date ? moment(initialValues.date, formatDate) : moment();
  const trueInitialValues = { ...initialValues, date: defaultDate };

  const fetchData = () => {
    api.fetchFirebase('tokens', updateTokens);
  };

  const handleOnFinish = async (data) => {
    if(!initialValues.title) {
      const tokensChunks = tokens.reduce((resultArray, token, index) => {
        const chunkIndex = Math.floor(index/100);
      
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }
      
        resultArray[chunkIndex].push(token.expo_push_token.data);
      
        return resultArray
      }, []);

      const tokensMessage = tokensChunks.map((tokens) =>(JSON.stringify({
        to: tokens,
        sound: 'default',
        title: data.title,
        body: data.body,
      })
      ))
      
      const requests = tokensMessage.map((chunk) => {
        return fetch('https://exp.host/--/api/v2/push/send', {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
          },
          body: chunk,
        });
      });

      /*try {
        await Promise.all(requests);
      } catch (err) {
        console.log('err', err);
      }*/
      
    }
    onFinish({ ...data, date: data.date.format(formatDate)});
  }
  useEffect(() => {
    if (!initialValues.title) {
      fetchData();
    }
  }, []);

  return (<Form
    name="Notofications"
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 18 }}
    onFinish={handleOnFinish}
    initialValues={trueInitialValues}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Por favor ingresa el titulo de la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Descripción"
      name="body"
      rules={[{ required: true, message: 'Por favor ingresa la descripción de la noticia' }]}
    >
      <MarkdownEditor
        body={body}
        onChange={setBody}
      />
    </Form.Item>

    <ImagePreviewForm
      preview={initialValues.image}
    />

    <Form.Item
      label="Fecha"
      name="date"
      rules={[{ required: true, message: 'Por favor ingresa el día de la noticia' }]}
    >
      <DatePicker format={formatDate} />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Aceptar
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
