import { IframeColumn, MarkdownColumn, ImageColumn, SwitchColumn } from "../../common/ColumnRenders";

const screenColumns = [
  {
    title: 'Titulo',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Descripción',
    dataIndex: 'body',
    key: 'body',
    render: MarkdownColumn,
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Principal',
    dataIndex: 'principal',
    key: 'principal',
    render: SwitchColumn,
  },
  {
    title: 'Video',
    dataIndex: 'video',
    key: 'video',
    render: IframeColumn,
  },
  {
    title: 'Imagen',
    dataIndex: 'image',
    key: 'image',
    render: ImageColumn,
  },
];

export default screenColumns;
