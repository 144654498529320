export const DATABASE_REFS = {
  INTEREST_SUBJECTS: 'interest-subjects',
  COMMITTEES: 'committees',
  NEWS: 'news',
  REPORTS: 'reports',
  EVENTS: 'events',
  MAGAZINES: 'magazines',
  CIRCULARES: 'circulars',
  WEBINARS: 'webinars',
  INDICATORS: 'indicators',
  ANTP_LIVE: 'antpLive',
  PARTNERS: 'partners',
  CALENDAR: 'calendar',
  NOTIFICATIONS: 'notifications',
  USERS: 'users',
};

export const MODAL_ACTIONS = {
  NONE: 'none',
  EDIT: 'edit',
  DELETE: 'delete',
  ADD: 'add',
};

export const formatDate = 'DD/MM/YYYY';

export const defaultImage = 'https://www.ghihornos.com/wp-content/uploads/2017/09/default.jpg';
export const defaultIframe = 'https://cdn2.iconfinder.com/data/icons/outline-web-application-1/20/code_1-512.png';

export const markdownCommands = [
  {
    name: 'Bold',
    command: 'bold',
    icon: null,
  },
  {
    name: 'Italica',
    command: 'italic',
    icon: null,
  },
  {
    name: 'Tachar',
    command: 'strike-through',
    icon: null,
  },
  {
    name: 'link',
    command: 'link',
    icon: null,
  },
  {
    name: 'Imagen',
    command: 'image',
    icon: null,
  },
  {
    name: 'Lista desordenada',
    command: 'unordered-list',
    icon: null,
  },
  {
    name: 'Lista ordenada',
    command: 'ordered-list',
    icon: null,
  },
  {
    name: 'H1',
    command: 'h1',
    icon: null,
  },
  {
    name: 'H2',
    command: 'h2',
    icon: null,
  },
  {
    name: 'H3',
    command: 'h3',
    icon: null,
  },
  {
    name: 'H4',
    command: 'h4',
    icon: null,
  },
  {
    name: 'H5',
    command: 'h5',
    icon: null,
  },
  {
    name: 'H6',
    command: 'h6',
    icon: null,
  },
];

export const HTTP_METHODS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
};

export const ANTP_API_TOKEN = 'antpApiToken';
export const ANTP_API_ID = 'antpApiId';
