import { ImageColumn, MarkdownColumn } from "../../common/ColumnRenders";

const screenColumns = [
  {
    title: 'Titulo',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Descripción',
    dataIndex: 'body',
    key: 'body',
    render: MarkdownColumn,
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Deeplink',
    dataIndex: 'deeplink',
    key: 'deeplink',
  },
  {
    title: 'URL al registro',
    dataIndex: 'url',
    key: 'url',
  },
  {
    title: 'Imagen',
    dataIndex: 'image',
    key: 'image',
    render: ImageColumn,
  },
];

export default screenColumns;
