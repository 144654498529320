import React from "react";
import { Button, Form, Input, DatePicker } from 'antd';
import moment from 'moment';
import '../../index.css';
import { formatDate } from "../../constants";

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const defaultDate = initialValues.date ? moment(initialValues.date, formatDate) : moment();

  const handleOnFinish = (data) => onFinish({ ...data, date: data.date.format(formatDate)});
  const trueInitialValues = { ...initialValues, date: defaultDate };

  return (<Form
    name="Calendar"
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 18 }}
    onFinish={handleOnFinish}
    initialValues={trueInitialValues}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Por favor ingresa el titulo del evento' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Descripción"
      name="body"
      rules={[{ required: true, message: 'Por favor ingresa la descripción del evento' }]}
    >
      <Input.TextArea />
    </Form.Item>
    
    <Form.Item
      label="Comité"
      name="committee"
      rules={[{ required: true, message: 'Por favor ingresa el nombre del comité al que pertenece el evento' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Día del evento"
      name="date"
      rules={[{ required: true, message: 'Por favor ingresa el día del evento' }]}
    >
      <DatePicker format={formatDate} />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
