const throwDefaultError = (error) => {
  alert('Ha ocurrido un error', error);
}

const fieldsEnum = {
  login: [
    'Correo electrónico',
    'Contraseña',
  ],
};

const findMissingFieldIdx = (args) => args.findIndex((val) => !val);

export const missingFields = (form, ...fields) => {
  const missingFieldIdx = findMissingFieldIdx(fields);

  if (missingFieldIdx > -1) {
    const fieldMissing = fieldsEnum[form][missingFieldIdx];
    alert('Campos incompletos', `Por favor completa el campo: ${fieldMissing}`);
    return true;
  }
  
  return false;
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = re.test(String(email).toLowerCase());
  if (!isValid) {
    alert('Correo electrónico inválido', `Por favor verifica que el dato sea correcto`);
    return false;
  }
  return true;
}

export default throwDefaultError;
