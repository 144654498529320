import React from "react";
import './App.css';
import './index.css';
import RootRouter from "./router";

class App extends React.Component {

  render() {
    return (<>
      <RootRouter />
    </>);
  }
}

export default App;
