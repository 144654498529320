import React, { useRef, useState } from "react";
import { Button, Row, Col, Input, DatePicker } from 'antd';
import { markdownCommands } from "../../constants";
import './styles.css';

const MarkdownButtons = ({ markdownRef }) => (<Row>
  <Col>
    {markdownCommands.map(({ name, command }) => (<Button
      type="primary"
      htmlType="button"
      onClick={() => markdownRef?.current?.trigger(command)}
      className="markdownButton"
    >
      {name}
    </Button>))}
  </Col>
</Row>);

export default MarkdownButtons;