import { MarkdownColumn, ImageColumn, SwitchColumn } from "../../common/ColumnRenders";

const screenColumns = [
  {
    title: 'Titulo',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Activa',
    dataIndex: 'active',
    key: 'active',
    render: SwitchColumn,
  },
  {
    title: 'Introducción',
    dataIndex: 'foreword',
    key: 'foreword',
  },
  {
    title: 'Contenido',
    dataIndex: 'content',
    key: 'content',
    render: MarkdownColumn,
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Edición',
    dataIndex: 'edition',
    key: 'edition',
  },
  {
    title: 'Número de edición',
    dataIndex: 'num_edition',
    key: 'num_edition',
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
  },
  {
    title: 'Comités',
    dataIndex: 'committees',
    key: 'committees',
  },
  {
    title: 'Url de imagen',
    dataIndex: 'url_image',
    key: 'url_image',
    render: ImageColumn,
  },
];

export default screenColumns;
