import { IframeColumn, ImageColumn } from "../../common/ColumnRenders";


const screenColumns = [
  {
    title: 'Titulo',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Edición',
    dataIndex: 'body',
    key: 'body',
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'PDF',
    dataIndex: 'url',
    key: 'url',
    render: IframeColumn,
  },
  {
    title: 'Imagen',
    dataIndex: 'image',
    key: 'image',
    render: ImageColumn,
  },
];

export default screenColumns;
