const screenColumns = [
  {
    title: 'Titulo',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Tag',
    dataIndex: 'tag',
    key: 'tag',
  },
];

export default screenColumns;
