import React, { useState } from "react";
import { Button, Form, Input, DatePicker, Switch } from 'antd';
import moment from 'moment';
import '../../index.css';
import { formatDate } from "../../constants";
import ImagePreviewForm from "../../common/ImagePreviewForm";
import MarkdownEditor from "../../common/MarkdownEditor";
import IframePreviewForm from "../../common/IframePreviewForm";

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const [body, setBody] = useState(initialValues.body);
  const defaultDate = initialValues.date ? moment(initialValues.date, formatDate) : moment();

  const handleOnFinish = (data) => onFinish({ ...data, date: data.date.format(formatDate)});
  const trueInitialValues = { ...initialValues, date: defaultDate };

  return (<Form
    name="Circulares"
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 18 }}
    onFinish={handleOnFinish}
    initialValues={trueInitialValues}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Por favor ingresa el titulo de la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Descripción"
      name="body"
      rules={[{ required: true, message: 'Por favor ingresa la descripción de la noticia' }]}
    >
      <MarkdownEditor
        body={body}
        onChange={setBody}
      />
    </Form.Item>

    <Form.Item
      label="Principal"
      name="principal"
      valuePropName="principal"
    >
      <Switch defaultChecked={initialValues.principal} />
    </Form.Item>
    
    <IframePreviewForm
      preview={initialValues.video}
      label="Video"
      name="video"
      optional
    />
    
    <ImagePreviewForm
      preview={initialValues.image}
    />

    <Form.Item
      label="Fecha"
      name="date"
      rules={[{ required: true, message: 'Por favor ingresa el día de la noticia' }]}
    >
      <DatePicker format={formatDate} />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
