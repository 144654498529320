import { ImageColumn } from "../../common/ColumnRenders";


const screenColumns = [
  {
    title: 'Empresa',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Logo',
    dataIndex: 'image',
    key: 'image',
    render: ImageColumn,
  },
];

export default screenColumns;
