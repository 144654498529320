import { HTTP_METHODS, ANTP_API_TOKEN, ANTP_API_ID } from "../constants";
import throwDefaultError, { missingFields, validateEmail } from '../errorHandler';

const { POST, GET, PUT } = HTTP_METHODS;

const uri = `https://socios.antp.org.mx/api/admin`;

class Api {
  defaultFetch = async (endPoint, requestData, method = GET) => {
    try {
      const token = 'MTQ5.J_UEWnMAJ4iDKyowq2JG7MstSh_7i-ju8oHONT-NoJdqzJBgAmx1pebDnQOi'; //await UserSettings.get(ANTP_API_TOKEN);
      const response = await fetch(`${uri}/${endPoint}`, {
        method,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });
  
      return response.json();
    } catch (error) {
      throwDefaultError(error.message);
    }
    
  }

  fetchFile = async (endPoint, requestData, method = GET) => {
    try {
      const token = 'MTQ5.J_UEWnMAJ4iDKyowq2JG7MstSh_7i-ju8oHONT-NoJdqzJBgAmx1pebDnQOi'; //await UserSettings.get(ANTP_API_TOKEN);
      const response = await fetch(`${uri}/${endPoint}`, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
        body: requestData,
      });
  
      return response.json();  
    } catch (error) {
      throwDefaultError(error.message);
    }
    
  }

  login = async (requestData) => {
    try {
      const { data, status } = await this.defaultFetch('login', requestData, POST);
      if (status !== 200) {
        throwDefaultError('El usuario no ha sido aprovado. Intenta más tarde.')
      }
      //UserSettings.set(ANTP_API_TOKEN, data.token);
      //UserSettings.set(ANTP_API_ID, data.id);
      return {
        status: 200,
        error: null,
      };
    } catch (error) {
      return {
        error: true,
        status: error.status,
      }
    }
  }

  uploadImage = async ({ uri }, callback) => {
    try {
      const id = '1'; //await UserSettings.get(ANTP_API_ID);
      
        const uriParts = uri.split('.');
        const fileType = uriParts[uriParts.length - 1];
    
        const formData = new FormData();

        formData.append('id', id);

        formData.append('imageProfile', {
          uri,
          name: `user_${id}_profile_image.${fileType}`,
          type: `image/${fileType}`,
        });

      const response = await this.fetchFile('updateUserProfileImage', formData, POST);

      callback(response);
    } catch (error) {
      throw error;
    }
  }

  getUserProfileImage = async (callback) => {
    try {
      const id = '1'; //await UserSettings.get(ANTP_API_ID);
      const { data } = await this.defaultFetch('userProfileImage', {
        id,
      }, POST);
      callback(data.image);
    } catch (error) {
      throw error;
    }
  }

  signIn = async (requestData) => {
    await this.defaultFetch('signIn', requestData, POST);
    await this.login({ email: requestData.email, password: requestData.password });
  }

  updateUserInterests = async (requestData) => {
    try {
      const id = '1'; //await UserSettings.get(ANTP_API_ID);
      const res = await this.defaultFetch('updateUserInterests', { ...requestData, id }, POST);
      return res;
    } catch (error) {
      return {
        error: true,
        status: error.status,
      }
    }
  }

  updateUserData = async (requestData, callback = {}) => {
    try {
      const id = '1'; //await UserSettings.get(ANTP_API_ID);
      const { data } = await this.defaultFetch('updateUserPersonalData', {
        ...requestData,
        id,
      }, POST);
      callback(data);
    } catch (error) {
      return {
        error: true,
        status: error.status,
      }
    }
  }

  getData = async (route, callback) => {
    const res = await this.defaultFetch(route);
    const { data } = await this.defaultFetch(route);
    if (callback) {
      callback(data);
    }
  }

  add = async (route, requestData, callback) => {
    const { data } = await this.defaultFetch(route, {
      ...requestData,
    }, POST);
    if (callback) {
      callback(data);
    }
  }

  delete = async (route, dataId, callback) => {
    const { data } = await this.defaultFetch(`delete/${route}/${dataId}`, { }, POST);
    if (callback) {
      callback(data);
    }
  }

  edit = async (route, requestData, callback) => {
    const { data } = await this.defaultFetch(route, {
      ...requestData,
    }, PUT);
    if (callback) {
      callback(data);
    }
  }

  getDataWithParams = async (route, params = {}, callback) => {
    try {
      const id = '1'; //await UserSettings.get(ANTP_API_ID);
      const res = await this.defaultFetch(route, { ...params, id }, POST);
      callback(res.data);
    } catch (error) {
      return {
        error: true,
        status: error.status,
      }
    }
  }
}

export default new Api();
