import { ImageColumn, MarkdownColumn } from "../../common/ColumnRenders";

const screenColumns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Descripción',
    dataIndex: 'content',
    key: 'content',
    render: MarkdownColumn,
  },
  {
    title: 'Imágen',
    dataIndex: 'image',
    key: 'image',
    render: ImageColumn,
  },
];

export default screenColumns;
