import React from "react";
import { Button, Form, Input } from 'antd';
import '../../index.css';
import IframePreviewForm from "../../common/IframePreviewForm";

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  return (<Form
    name="Indicators"
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 18 }}
    onFinish={onFinish}
    initialValues={initialValues}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Por favor ingresa el titulo del indicador' }]}
    >
      <Input />
    </Form.Item>

    <IframePreviewForm
      preview={initialValues.url}
      name="url"
      Lanel="Url"
    />

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
