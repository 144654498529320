import React from "react";
import { Button, Form, Input, } from 'antd';
import ImagePreviewForm from "../../common/ImagePreviewForm";
import '../../index.css';

const ScreenForm = ({ onFinish, initialValues = {} }) => (<Form
  name="Committee"
  labelCol={{ offset: 1, span: 4 }}
  wrapperCol={{ span: 16 }}
  onFinish={onFinish}
  initialValues={initialValues}
>
  <Form.Item
    label="Nombe"
    name="name"
    rules={[{ required: true, message: 'Por favor ingresa el titulo del comité' }]}
  >
    <Input />
  </Form.Item>

  <Form.Item
    label="Descripción"
    name="content"
    rules={[{ required: true, message: 'Por favor ingresa la tag del comité' }]}
  >
    <Input.TextArea />
  </Form.Item>
  
  <ImagePreviewForm
    preview={initialValues.image}
    name="image"
  />

  <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
    <Button type="primary" htmlType="submit">
      Submit
    </Button>
  </Form.Item>
</Form>);

export default ScreenForm;
