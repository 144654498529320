const screenColumns = [
  {
    title: 'Titulo',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Descripción',
    dataIndex: 'body',
    key: 'body',
  },
  {
    title: 'Día del evento',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Comité',
    dataIndex: 'committee',
    key: 'committee',
  },
];

export default screenColumns;
