import React, { useState, useEffect } from "react";
import { Switch, Button, Form, Input, InputNumber, DatePicker, AutoComplete } from 'antd';
import moment from 'moment';
import api2 from "../../api2";
import '../../index.css';
import { formatDate } from "../../constants";
import ImagePreviewForm from "../../common/ImagePreviewForm";
import MarkdownEditor from "../../common/MarkdownEditor";
import TagsButtons from "../../common/TagsButtons";

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const [content, setContent] = useState(initialValues.content);
  const [tags, updateTags] = useState([]);
  const [committees, updateCommittees] = useState([]);
  const [tagsSelected, updateTagsSelected] = useState([]);
  const [committeesSelected, updateCommitteesSelected] = useState([]);
  const defaultDate = initialValues.date ? moment(initialValues.date, formatDate) : moment();

  const handleOnFinish = (data) => {
    onFinish({
      ...data,
      date: data.date.format(formatDate),
      tags: tagsSelected.join(),
      committees: committeesSelected.join(),
    });
  };
  const trueInitialValues = { ...initialValues, date: defaultDate };

  const fetchData = () => {
    api2.getData('tags', (tagsRes) => {
      updateTags(tagsRes.map((tr) => ({
        value: tr.key,
        label: tr.name,
      })))
    });
    api2.getData('committees', (committeesRes) => {
      updateCommittees(committeesRes.map((tr) => ({
        value: tr.key,
        label: tr.name,
      })))
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (<Form
    name="Circulares"
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 18 }}
    onFinish={handleOnFinish}
    initialValues={trueInitialValues}
  >
    <Form.Item
      label="Activa"
      name="active"
      valuePropName="active"
    >
      <Switch defaultChecked={trueInitialValues.active} />
    </Form.Item>
  
    <Form.Item
      label="Contenido"
      name="content"
      rules={[{ required: true, message: 'Por favor ingresa el copntenido del circular' }]}
    >
      <MarkdownEditor
        text={content}
        onChange={setContent}
      />
    </Form.Item>

    <Form.Item
      label="Fecha"
      name="date"
      rules={[{ required: true, message: 'Por favor ingresa el día del circular' }]}
    >
      <DatePicker format={formatDate} />
    </Form.Item>

    <Form.Item
      label="Edición"
      name="edition"
      rules={[{ required: true, message: 'Por favor ingresa el nombre del circular' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Introducción"
      name="foreword"
      rules={[{ required: true, message: 'Por favor ingresa el titulo del circular' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Número de edición"
      name="num_edition"
      rules={[{ required: true, message: 'Por favor ingresa el número de edición del circular' }]}
    >
      <InputNumber />
    </Form.Item>

    <Form.Item
      label="Tags"
      rules={[{ required: true, message: 'Por favor ingresa el titulo del circular' }]}
    >
      <AutoComplete
        allowClear
        options={tags}
        onSelect={(opt) => {
          updateTagsSelected([...tagsSelected, opt]);
        }}
        placeholder="Selecciona las tags"
        filterOption
      />
      <TagsButtons
        tags={tagsSelected}
        onSelect={(tag) => {
          const filteredTags = tagsSelected.filter((tg) => tg !== tag);
          updateTagsSelected(filteredTags);
        }}
      />
    </Form.Item>

    <Form.Item
      label="Committees"
      rules={[{ required: true, message: 'Por favor ingresa el titulo del circular' }]}
    >
      <AutoComplete
        allowClear
        options={committees}
        onSelect={(opt) => {
          updateCommitteesSelected([...committeesSelected, opt]);
        }}
        placeholder="Selecciona los comités"
        filterOption
      />
      <TagsButtons
        tags={committeesSelected}
        onSelect={(comm) => {
          const filteredCommittees = committeesSelected.filter((tg) => tg !== comm);
          updateCommitteesSelected(filteredCommittees);
        }}
      />
    </Form.Item>

    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Por favor ingresa el titulo del circular' }]}
    >
      <Input />
    </Form.Item>

    <ImagePreviewForm
      preview={trueInitialValues.url_image}
    />

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
